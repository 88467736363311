// Fonts
@import "https://fonts.googleapis.com/css2?family=Gayathri:wght@100;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Alfa+Slab+One&display=swap";
@import "https://fonts.googleapis.com/css2?family=Libre+Baskerville&family=Yeseva+One&display=swap";
@import "media-queries";

@font-face {
  font-family: "PPMori";
  src: url("./fonts/PPMori-Regular.otf") format('truetype');
}
@font-face {
  font-family: "PPMori-Light";
  src: url("./fonts/PPMori-Extralight.otf") format('truetype');
}
@font-face {
  font-family: "PPMori-Bold";
  src: url("./fonts/PPMori-SemiBold.otf") format('truetype');
}

.all-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    h1{
        font-size: 150px;
        color: #42682f;
        text-align: center;
        line-height: 25vmin;
    }
    h2{
        font-size: 20px;
    }
    p, li, a{
        font-size: 17px;
        color: black;
    }
}

.all-overview{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    li{
        list-style-type: circle;
    }
}

.all-tech-stack{
    width: 20vmin;
    padding: 2vmin;
    display: flex;
    flex-direction: column;
    margin: 0 10vmin 0 0;
    white-space: nowrap;
}

.all-proj-overview{
    width: 60vmin;
    padding: 2vmin;
    margin: 0 0 0 10vmin;
}

.all-images{
    margin: 10vmin 0;
    img{
        width: 950px;
        height: 600px;
    }
    section{
        display: flex;
        justify-content: center;
    }
}
.all-left-arrow, .all-right-arrow{
    font-size: 2.2rem;
    margin: 2vmin 2vmin;
    border-radius: 2vmin;
    border: 0.5vmin solid #42682f;;
}
.all-footer{
    margin-bottom: 5vmin;
    a{
        display: block;
        text-align: center;
        font-size: 60px;
        font-weight: bold;
        text-decoration: none;
        color: #42682f;
    }
    a:nth-child(2){
        font-size: 30px;
    }
}

// MEDIA QUERIES --------------------------------------------------------
@media screen and (min-width: 110rem) {
    .all-images{
        img{
            width: 1150px;
            height: 1150px;
        }
    }
}

@media screen and (max-width: 70rem) {
    .all-images{
        img{
            width: 950px;
            height: 480px;
        }
    }
    .all-left-arrow, .all-right-arrow{
        font-size: 2.0rem;
    }
}

// IPAD
@media screen and (max-width: 55rem) {
    .all-container {
        h2{
            font-size: 19px;
        }
    }
    .all-left-arrow, .all-right-arrow{
        font-size: 1.2rem;
    }
    .all-overview{
        margin: 0 5vmin;
    }
    .all-tech-stack{
        width: 100%;
        margin: 0;
    }
    .all-proj-overview{
        width: 100%;
        margin: 0;
    }
}

// Intermediate screen size
@media screen and (max-width: 45rem) {
    .all-images{
        img{
            width: 530px;
            height: 400px;
        }
    }
}

// Surface Duo
@media screen and (max-width: 40rem) {
    .all-container {
        h1 {
            font-size: 100px;
        }
        h2{
            font-size: 17px;
        }
        p, li, a{
            font-size: 16px;
        }
    }
    .all-images{
        img{
            width: 410px;
            height: 300px;
        }
    }
    .all-left-arrow, .all-right-arrow{
        font-size: 1.2rem;
    }
    .all-footer {
        a{
            font-size: 33px;
        }  
        a:nth-child(2){
            font-size: 16px;
        }
    }
    
}

// iphone 12 pro max, iphone 13 pro max
@media screen and (max-width: 428px) and (max-height : 926px) {
    .all-images{
        img{
            width: 330px;
        }
    }
}
  
// iPhone 11, 11 Pro Max, XR, S20, A51/71
@media screen and (max-width: 414px) and (max-height: 896px)  {
    .all-container h1{
        font-size: 90px;
    }
    .all-images{
        img{
            width: 310px;
        }
    }
}
  
// iphone 8 plus
@media screen and (max-width: 414px) and (max-height: 736px)  {
    .all-container h1{
        font-size: 90px;
    }
    .all-images{
        img{
            width: 310px;
        }
    }
}
  
// 13, 13 Pro, 12, 12 Pro, Pixel 5
@media screen and (max-width: 390px) and (max-height: 844px)  {
    .all-container h1{
        font-size: 80px;
    }
}
  
  
// iPhone 11 Pro, 11 X, 13 Mini, 12 Mini, X, XS
@media screen and (max-width: 375px) and (max-height: 812px)  {
    .all-container h1{
        font-size: 80px;
    }
}
  
// SE + 7, 8
@media screen and (max-width: 375px) and (max-height: 667px)  {
    .all-container h1{
        font-size: 80px;
    }
    .all-left-arrow, .all-right-arrow{
        font-size: 1.2rem;
    }
}
  
// Samsung 8+
@media screen and (max-width: 23rem) {
    .all-container h1{
        font-size: 80px;
    }
    .all-images{
        img{
            width: 335px;
        }
    }
}
  
// Galaxy fold
@media screen and (max-width: 22rem)  {
    .all-container {
        h1{
            font-size: 65px;
        }
        h2{
            font-size: 17px;
        }
        p, li, a{
            font-size: 15px;
        }
    }
    .all-images{
        img{
            width: 250px;
            height: 200px;
        }
    }
    .all-left-arrow, .all-right-arrow{
        font-size: 1.0rem;
    }
}