.project-gallery{
    max-width: 140vmin; /* Adjust max-width as needed */
    margin: 0 auto; /* Center align the gallery */
    display: flex;
    flex-direction: column;
    align-items: center;
  
    section{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  
  .project{
    width: 100%; /* Ensure the element fills the available width */
    margin: 6vmin 0;
    border-radius: 2vmin;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
  }
  
  .project-img{
    flex: 2;
    img{
      display: block;
      width: 100%;
      height: 60vmin;
      object-fit: cover;
      border-top-left-radius: 2vmin;
      border-bottom-left-radius: 2vmin;
    }
  }
  
  .project-text{
    flex: 1;
    padding: 2vmin 4vmin;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h3{
      font-size: 20.8px;
      line-height: 27px;
      margin: 0;
      font-family: "PPMori";
    }
    p{
      margin-bottom: 7vmin;
      font-family: 'PPMori-Light';
      font-size: 16px;
    }
    span{
      background-color: #42682f;
      border-radius: 2vmin;
      padding: 1.5vmin 2.2vmin;
      font-size: 14px;
      font-family: 'PPMori';
    }
    a{
      text-decoration: none;
      color: white;
    }
  }
  
  /* hover effect on projects */
  .project:hover{
    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
    /* transform creates illusion of box being lifted */
    transform: translateY(-5px);
  }
  
  @media screen and (max-width: 780px) {
    .project{
      flex-direction: column;
      width: 70vmin;
    }
    .project-img{
      width: 100%;
      img{
        height: 40vmin;
        object-fit: fill;
        border-radius: 0vmin;
        border-top-left-radius: 2vmin;
        border-top-right-radius: 2vmin;
      }
    }
    .project-text{
      width: 80%;
      margin: 3vmin;
      p{
        margin-bottom: 4vmin;
      }
    }
  }
  
// Media Queries 
@media screen and (max-width: 40rem) {
  .project-text {
    h3 {
    font-size: 17.8px;
    line-height: 24px; 
    } 
    p{
      font-size: 13px;
    }
    span{
      font-size: 11px;
    }
  }
}
  
@media screen and (max-width: 26rem) {
  .project-text {
    h3 {
    font-size: 16.8px;
    line-height: 23px;
    }
    p{
      font-size: 12px;
    }
    span{
      font-size: 10px;
    }
  }
}