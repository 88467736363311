@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.landing-container{
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 0 6vmin 0;
}

.gradient-container {
  background: linear-gradient(-45deg, #6e4825, 
  #0a3e3e, #0a3b1b);
  background-size: 400% 400%;
  animation: gradient 10s ease infinite;
  height: 85vmin;
  width: 92%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 5vmin;

  h1, h2, a{
    color: whitesmoke;
    text-decoration: none;
    margin:0;
  }
  h1{
    font-size: 65px;
    line-height: 84.5px;
    font-family: 'PPMori';
    padding: 0 4vmin;
  }
  h2, a{
    font-size: 24px;
    line-height: 31.2px;
    font-family: 'PPMori-Light';
  }
  ul{
    list-style: none;
    margin:0;
  }
  h2{
    padding-right: 2vmin;
  }
  ul{
    padding-left: 2vmin;
  }
}

.greeting {
  display: inline-block;
  h1{
    text-align: center;
  }
}

.home-links{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

 
// HOVER effect over links ----------------------
.links{
  margin-left: 5vmin;

  span{
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
}

.links span:before, .links span:after {
  content: '';
  position: absolute;
  width: 0%;
  height: 0.2vmin;
  top: 50%;
  background: #42682f;
}
.links span:before {
  left: -2.6px;
}
.links span:after {
  right: 2.6px;
  background:#fff;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.links span:hover:before {
  background: #fff;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}
.links span:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}

 
// Media Queries 
@media screen and (max-width: 50rem) {
  .gradient-container {
    h1 {
      font-size: 58px;
      line-height: 75.4px;
    } 
    h2, a{
      font-size: 20px;
      line-height: 26px;
    }
  }
}

@media screen and (max-width: 40rem) {
  .gradient-container {
    h1 {
      font-size: 50px;
      line-height: 65px;
    } 
    h2, a{
      font-size: 16px;
      line-height: 20.8px;
    }
  }
}
  
@media screen and (max-width: 35rem) {
  .gradient-container {
    h1 {
      font-size: 45px;
      line-height: 58.5px;
    } 
    h2, a{
      font-size: 15px;
      line-height: 19.5px;
    }
  }
}

@media screen and (max-width: 33rem) {
  .gradient-container {
    h1 {
      font-size: 40px;
      line-height: 48px;
    } 
    h2, a{
      font-size: 14px;
      line-height: 14.2px;
    }
  }
}
